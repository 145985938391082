import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref, provide } from "vue";
import FloatingMenu from "@/components/floatingMenu/index.vue";
import { useRoute } from "vue-router";
import router from "@/router/index";
import { getColtdApi } from "@/api/coltd";
import $ from "jquery";
export default defineComponent({
    components: { FloatingMenu: FloatingMenu
    },
    setup: function () {
        var _this = this;
        var route = useRoute();
        var coltd = ref("");
        provide("coltd", coltd);
        //浏览器刷新重定向
        if (route.path != "/home") {
            router.push("/");
        }
        //获取公司信息
        var getColtd = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = coltd;
                        return [4 /*yield*/, getColtdApi({
                                currentPage: 1,
                                size: 1,
                            })];
                    case 1:
                        _a.value = _b.sent();
                        coltd.value = coltd.value.records[0];
                        return [2 /*return*/];
                }
            });
        }); };
        onMounted(function () {
            // getColtd();
            window.addEventListener("scroll", scrollToTop); // 监听滚动事件
        });
        //路由监听，防止子组件方法提前加载
        router.beforeEach(function (to, from, next) {
            if (coltd.value) {
                next();
            }
            else {
                getColtd();
                setTimeout(function () {
                    next();
                }, 500);
            }
        });
        var scrollToTop = function () {
            var scrollTop = window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop > 20) {
                $(".controlHeader").css("background", "#fff");
            }
            if (scrollTop < 20) {
                $(".controlHeader").css("background", "transparent");
            }
            if (scrollTop > 200) {
                //滑动时关闭头部菜单栏
                $("#navbarToggleExternalContent").removeClass("show");
            }
            if (scrollTop > 600) {
                $(".rightbackTop").fadeIn();
            }
            else {
                $(".rightbackTop").fadeOut();
            }
        };
        //获取点击时页面的滚动条纵坐标位置
        var backTop = function () {
            var top = document.documentElement.scrollTop;
            var timeTop = setInterval(function () {
                document.documentElement.scrollTop = top -= 80;
                if (top <= 0) {
                    clearInterval(timeTop);
                }
            }, 5);
        };
        return {
            route: route,
            scrollToTop: scrollToTop,
            backTop: backTop,
            coltd: coltd,
        };
    },
});
