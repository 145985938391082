import { __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, ref, inject } from "vue";
import Dialog from "@/components/dialog/index.vue";
import { sendMailApi } from "@/api/email";
import $ from "jquery";
export default defineComponent({
    components: { Dialog: Dialog },
    setup: function () {
        var _this = this;
        var coltd = inject("coltd");
        //模态框调用
        var dialogVisible = ref(false);
        var content = ref("");
        var toEmail = ref("");
        var sendMail = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, sendMailApi({
                            cc: [],
                            content: content.value,
                            subject: "来自因格艾官方网站产品咨询",
                            to: "services@yingeai.com"
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var tipShow = function () {
            dialogVisible.value = true;
        };
        var close = function (typeName, submitConsultData, phone, email) {
            if ((typeName != null && submitConsultData != null) && (phone != null || email != null)) {
                content.value = "----咨询产品名称:" + typeName + "----咨询项目:" + submitConsultData.substring(2, submitConsultData.length - 2) + "----email地址:" + email + "----手机号码:" + phone;
                //提交
                sendMail();
            }
            //TODO 取消
            dialogVisible.value = false;
            console.log(dialogVisible.value, "close-props");
        };
        //菜单栏悬停
        var handleMouseEnter = function () {
            $(".floatingMenu").stop().animate({
                width: 180,
            });
            $(".hideTitle").fadeIn();
            $(".hideTitle").css("color", "#1f63fb");
        };
        var handleMouseLeave = function () {
            $(".floatingMenu").stop().animate({
                width: 55,
            });
            $(".hideTitle").fadeOut();
        };
        return {
            handleMouseEnter: handleMouseEnter,
            handleMouseLeave: handleMouseLeave,
            dialogVisible: dialogVisible,
            tipShow: tipShow,
            close: close,
            coltd: coltd,
        };
    },
});
