import axios from "@/utils/request";
export const productApi = (data: any) => {
  return axios({
    path: "/productInfo/list",
    method: "POST",
    data
  })
}
export const productTypeApi = () => {
  return axios({
    path: "/productType/selectAll",
    method: "POST",
  })
}
export const productByTypeApi = (data: any) => {
  return axios({
    path: "/productInfo/getByProdutTypeId",
    method: "POST",
    data
  })
}

export const productImgApi = (data: any) => {
  return axios({
    path: "/productImg/selectByProductId",
    method: "POST",
    data
  })
}