import { __assign, __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, ref, reactive, inject, unref, onMounted } from "vue";
import { productTypeApi } from "@/api/product";
export default defineComponent({
    emits: ["close"],
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        }
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var coltd = inject("coltd");
        var validateForm = ref({
            email: '',
            phone: ''
        });
        var consultData = reactive({
            list: [
                {
                    id: 1,
                    consultName: "我希望与客服代表联系"
                },
                {
                    id: 2,
                    consultName: "我希望了解价格"
                },
                {
                    id: 3,
                    consultName: "其他"
                }
            ]
        });
        var defaultOption = ref(1);
        var checkboxGroup = ref(['我希望与客服代表联系']);
        var checkFlag = ref(false);
        var activeName = ref("first");
        var validateFormRef = ref();
        var optionProduct = ref("");
        var optionConsultData = reactive({
            list: [{
                    id: 1,
                    consultName: "我希望与客服代表联系"
                }
            ]
        });
        var submitConsultData = ref([]);
        //获取产品类型列表
        var productTypeList = ref("");
        var getProductTypeList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = productTypeList;
                        return [4 /*yield*/, productTypeApi()];
                    case 1:
                        _a.value = _b.sent();
                        if (productTypeList.value != "") {
                            optionProduct.value = productTypeList.value[0];
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        //选择产品类别
        var handleChange = function (val) {
            optionProduct.value = productTypeList.value.find(function (i) {
                return i.id === val;
            });
        };
        //选择咨询的项目
        var changeCheckbox = function (item, e) {
            if (e) {
                if (!optionConsultData.list.includes(item)) {
                    optionConsultData.list.push(consultData.list.find(function (i) {
                        return i.id === item.id;
                    }));
                }
            }
            else {
                if (optionConsultData.list.length > 0) {
                    var index = void 0;
                    for (index in optionConsultData.list) {
                        if (optionConsultData.list[index].id == item.id) {
                            optionConsultData.list.splice(index, 1);
                        }
                    }
                }
            }
        };
        // 验证邮箱的自定义规则
        var checkEmail = function (rule, value, cb) {
            var emailRegExp = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
            var emailRegExp1 = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
            if (emailRegExp.test(value) && emailRegExp1.test(value)) {
                checkFlag.value = true;
                return cb();
            }
            cb(Error('请输入合法的邮箱地址！'));
        };
        // 验证手机号码的自定义规则
        var checkPhone = function (rule, value, cb) {
            var phoneRegExp1 = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
            if (phoneRegExp1.test(value)) {
                checkFlag.value = true;
                return cb();
            }
            cb(Error('请输入合法的手机号码！'));
        };
        // 定义校验规则
        var addOrEditorRules = reactive({
            email: [
                { required: true, message: "请输入邮箱地址", trigger: "blur" },
                { validator: checkEmail, message: "请输入正确的邮箱地址", trigger: "blur" }
            ],
            phone: [
                { required: true, message: "手机号码不能为空", trigger: "blur" },
                { validator: checkPhone, message: "手机号码格式不正确", trigger: "blur" }
            ],
        });
        //提交
        var handleSubmit = function () {
            filterConsultData();
            var form = unref(validateFormRef);
            if (!form) {
                return;
            }
            form.validate();
        };
        var filterConsultData = function () {
            if (optionConsultData.list.length == 0) {
                optionConsultData.list = [{
                        id: 1,
                        consultName: "我希望与客服代表联系"
                    }];
            }
            submitConsultData.value = [];
            var index;
            for (index in optionConsultData.list) {
                submitConsultData.value.push(Number(index) + 1 + " : " + optionConsultData.list[index].consultName + ".");
            }
            if (checkFlag.value) {
                emit("close", optionProduct.value.typeName, JSON.stringify(submitConsultData.value), validateForm.value.phone, validateForm.value.email);
            }
            checkboxGroup.value = ["我希望与客服代表联系"];
            optionProduct.value = productTypeList.value[0];
            defaultOption.value = 1;
            checkFlag.value = false;
            validateForm.value.phone = "";
            validateForm.value.email = "";
            optionConsultData.list =
                [{
                        id: 1,
                        consultName: "我希望与客服代表联系"
                    }];
        };
        //取消
        var handleCancel = function () {
            var form = unref(validateFormRef);
            form.resetFields();
            emit("close");
        };
        onMounted(function () {
            getProductTypeList();
        });
        return __assign(__assign({ handleSubmit: handleSubmit,
            handleCancel: handleCancel,
            handleChange: handleChange,
            changeCheckbox: changeCheckbox,
            addOrEditorRules: addOrEditorRules,
            validateForm: validateForm,
            validateFormRef: validateFormRef,
            defaultOption: defaultOption,
            checkboxGroup: checkboxGroup,
            productTypeList: productTypeList }, consultData), { activeName: activeName,
            coltd: coltd });
    },
});
