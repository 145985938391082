import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/index";
import "./styles/index.scss";
import 'bootstrap/dist/js/bootstrap.min'
import "bootstrap/dist/css/bootstrap.css"
import lazyPlugin from 'vue3-lazy'
import WebpPlugun from 'vue-webp-plugin';
import 'jquery';
// import ElementPlus from 'element-plus';
// import 'element-plus/theme-chalk/index.css'
// import svg from '@element-plus/icons-vue'
// 国际化
// import { createI18n } from "vue-i18n";
// import messages from "@/utils/language";
//阿里图标
// import "@/components/svg/svg";
// import icons from "@/components/svg/svg.vue";
// import 'xe-utils';
// import 'vxe-table/lib/style.css';
// const i18n = createI18n({
//   locale: "zh-CN", //默认显示的语言
//   messages,
// });

createApp(App)
  .use(router)
  .use(WebpPlugun)
  .use(lazyPlugin, {
    // loading: require('@/assets/icons/tagRunner-1.png'), // 图片加载时默认图片
    // error: require('@/assets/icons/tagRunner-1.png') // 图片加载失败时默认图片
    loading: "", // 图片加载时默认图片
    error: "" // 图片加载失败时默认图片
})
  .mount("#app") 
