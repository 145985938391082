import request from 'axios';
import { config } from '@/utils/config'//引入公用文件
request.defaults.timeout = 3000;//请求超时时间
// 创建axios实例
// const service = axiosNew.create({
//   baseURL: config.timeout.
//     timeout: config.timeout // 请求超时时间
// })
request.interceptors.request.use(//请求拦截
  config => {
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    // let token =sessionStorage.getItem('token');
    // let token=store.getters.getToken;
    // if (token) {//如果有token给请求头加上
    //     config.headers.common['token'] =token
    // }
    return config
  },
  err => {
    return Promise.reject(err);
  }

)
declare module "axios" {
  interface AxiosResponse<T = any> {
    obj: [];
  }
}
// response 拦截器
request.interceptors.response.use(response => {
  if (response.data.code != undefined && (response.data.code == 401 || response.data.code == 403 || response
    .data.code == 500)) {
    console.log('服务器内部错误');
  } else {
    return response.data
  }
}, (err: any) => {
  //TODO
  return Promise.reject(err)
}
)

const axios = function ({ path, method = "get", data = {} }: any = {}) {
  return new Promise((resolve, reject) => {
    let datas: object = { params: { ...data } }
    if (method === "POST") datas = { ...{ data } };
    request({
      url: `${config.baseUrl}${path}`,
      method,
      ...datas
    }).then(res => {
      resolve(res.obj)
    }).catch(err => { resolve(-1) })
  })
};
export default axios



