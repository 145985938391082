import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
// RouteRecordRaw 是一个路由记录的原始数据类型，包含了路由的所有信息
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/index.vue'),
  },
  {
    path: '/nav',
    name: 'nav',
    component: () => import('../components/nav/nav.vue'),
  },
  {
    path: '/downloadBrochure',
    name: 'downloadBrochure',
    component: () => import('../views/downloadBrochure/index.vue'),
  },
  {
    path: '/productInformation',
    name: 'productInformation',
    component: () => import('../views/productInformation/index.vue'),
    children: [
      {
        path: 'productCategory',
        name: "productCategory",
        component: () => import("../views/productInformation/productCategory/index.vue"),
      },
      {
        path: 'productDetails',
        name: "productDetails",
        component: () => import("../views/productInformation/productDetails/index.vue"),
      },
    ],
  },
  {
    path: '/newsInformation',
    name: 'newsInformation',
    component: () => import("../views/newsInformation/index.vue"),
    children: [
      {
        path: 'newsList',
        name: "newsList",
        component: () => import("../views/newsInformation/newsList/index.vue"),
      },
      {
        path: 'newsDetail',
        name: "newsDetail",
        component: () => import("../views/newsInformation/newsDetail/index.vue"),
      },
    ],
  },
  {
    path: '/companyProfile',
    name: 'companyProfile',
    component: () => import('../views/companyProfile/index.vue'),
    children: [
      {
        path: 'aboutUs',
        name: "aboutUs",
        component: () => import("../views/companyProfile/aboutUs/index.vue"),
      },
      {
        path: 'recruitment',
        name: "recruitment",
        component: () => import("../views/companyProfile/recruitment/index.vue"),
      },
    ],
  },
  // {
  //   path: '/productSubject',
  //   name: 'productSubject',
  //   component: () => import('@/views/productSubject/index.vue'),
  // },
  // {
  //   path: '/contactWay',
  //   name: 'contactWay',
  //   component: () => import('@/views/contactWay/index.vue'),
  // },
  // {
  //   path: '/certified',
  //   name: 'certified',
  //   component: () => import('@/views/certified/index.vue'),
  // },
  // {
  //   path: '/termsOfUse',
  //   name: 'termsOfUse',
  //   component: () => import('@/views/termsOfUse/index.vue'),
  // },
  // {
  //   path: '/privacy',
  //   name: 'privacy',
  //   component: () => import('@/views/privacy/index.vue'),
  // },
  // {
  //   path: '/askKeyence',
  //   name: 'askKeyence',
  //   component: () => import('@/views/askKeyence/index.vue'),
  // },

];
// 创建路由对象
const router = createRouter({
  history: createWebHistory(), // 历史记录
  routes, // 路由的映射
  //当切换到新路由时，想要页面滚到顶部，或者是保持原先的滚动位置
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    // vue3.0  left  top 控制
    return { left: 0, top: 0 }
  }
})
// router.onError((error) => {
//   const pattern = /Loading chunk (\d)+ failed/g;
//   const isChunkLoadFailed = error.message.match(pattern);
//   if (isChunkLoadFailed) {
//     // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，
//     //而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
//     location.reload();
//     // const targetPath = $router.history.pending.fullPath;
//     // $router.replace(targetPath);
//   }
// });


export default router